import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)

export default new Vuetify({
  theme: { dark: false },
  lang: {
    locales: { es },
    current: 'es'
  }
})
