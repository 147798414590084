export default {
  path: '/tenants',
  redirect: { name: 'Tenants' },
  component: () => import('@/layouts/default/DefaultLayout'),
  children: [
    {
      name: 'Tenants',
      path: 'index',
      component: () => import('./views/TenantsView'),
      meta: {
        requiresAuth: true,
        title: 'Tenants',
        footer: true
      }
    },
    {
      name: 'AdminTenant',
      path: 'admin/:tid',
      component: () => import('./views/AdminTenant'),
      meta: {
        requiresAuth: true,
        title: 'Admin Tenant',
        footer: true
      }
    }
  ]
}
