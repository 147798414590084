import Vue from 'vue'
import VueRouter from 'vue-router'

import requestsRouter from '@/modules/requests/router'
import tenantsRouter from '@/modules/tenants/router'
import pagaresRouter from '@/modules/securities/router'
import gestionRoutes from '@/modules/gestion/router'
import temporaryAccessRouter from '@/modules/securities/temporaryAccessRouter'

Vue.use(VueRouter)

const routes = [
  requestsRouter,
  tenantsRouter,
  pagaresRouter,
  gestionRoutes,
  temporaryAccessRouter,
  {
    path: '/',
    redirect: { name: 'Home' },
    component: () => import('@/layouts/default/DefaultLayout'),
    children: [
      {
        name: 'Home',
        path: '/home',
        component: () => import('@/modules/home/HomeView'),
        meta: {
          requiresAuth: true,
          title: 'Home',
          footer: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
