import Vue from 'vue'
import Vuex from 'vuex'

import layoutStore from '@/layouts/default/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    layoutStore
  }
})
