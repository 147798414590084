import Vue from 'vue'

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
// eslint-disable-next-line camelcase
import { required, email, numeric, is_not, confirmed, alpha_dash, regex, min_value, max_value } from 'vee-validate/dist/rules'

Vue.use({
  install (Vue) {
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)
  }
})

extend('required', {
  ...required,
  message: 'Este campo  {_field_}  es obligatorio'
})

extend('min_value', {
  // eslint-disable-next-line camelcase
  ...min_value,
  message: 'El valor no es valido'
})

extend('max_value', {
  // eslint-disable-next-line camelcase
  ...max_value,
  message: (fieldName, placeholders) => {
    const maxValue = '$' + new Intl.NumberFormat('de-DE').format(placeholders.max)
    return `El campo ${fieldName} no puede ser mayor de ${maxValue}`
  }
})

extend('regex', {
  ...regex,
  message: 'El campo {_field_} no cumple con el formato'
})

extend('email', {
  ...email,
  message: 'El correo no es valido'
})

extend('numeric', {
  ...numeric,
  message: 'El campo {_field_} debe ser de tipo número únicamente'
})

extend('alpha_dash', {
  // eslint-disable-next-line camelcase
  ...alpha_dash,
  message: 'El campo {_field_} no puede contener espacios'
})

extend('no_space', {
  validate (value) {
    const regex = /^\S*$/
    return regex.test(value)
  },
  message: 'El campo {_field_} no puede contener espacios'
})

extend('phoneNumber', {
  validate (value) {
    const regex = /^[\+][0-9]{12}/im
    return regex.test(value)
  },
  message: 'El número de telefono debe estar en el formato +############'
})

extend('minlength', {
  params: ['length'],
  validate (value, { length }) {
    return String(value).length >= length
  },
  message: 'El campo {_field_} debe tener como mínimo {length} caracteres'
})

extend('maxlength', {
  params: ['length'],
  validate (value, { length }) {
    return String(value).length <= length
  },
  message: 'El campo {_field_} debe tener como maximo {length} caracteres'
})

extend('address', {
  validate (value) {
    return (isNaN(value) && value.trim().length > 5) || value > 0
  },
  message: 'El campo {_field_} no es un dato válido'
})

extend('is_not', {
  // eslint-disable-next-line camelcase
  ...is_not,
  message: 'El campo {_field_} debe ser diferente de la contraseña actual'
})

extend('confirmed', {
  ...confirmed,
  message: 'El campo {_field_} no coincide con el campo a confirmar'
})

extend('regexPassword', {
  params: ['password'],
  validate (value, { password }) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{8,})/
    return regex.test(password)
  },
  message: 'La contraseña debe ser alfanumerica, debe contener mayusculas y minusculas y un caracter especial'
})
