import { I18n } from 'aws-amplify'
import { Translations } from '@aws-amplify/ui-components'

I18n.putVocabulariesForLanguage('es', {
  [Translations.BACK_TO_SIGN_IN]: 'Volver a Inicio de sesión',
  [Translations.CHANGE_PASSWORD_ACTION]: 'Cambiar',
  [Translations.CHANGE_PASSWORD]: 'Cambiar Password',
  [Translations.CODE_LABEL]: 'Código de verificación',
  [Translations.CODE_PLACEHOLDER]: 'Ingrese el código',
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Codigo de confirmación',
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'Ingrese su código',
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Confirme su registro',
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: '¿Perdió su código?',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Reenviar código',
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Confirmar',
  [Translations.CONFIRM_SMS_CODE]: 'Confirmar SMS Code',
  [Translations.CONFIRM_TOTP_CODE]: 'Confirmar TOTP Code',
  [Translations.CONFIRM]: 'Confirmar',
  [Translations.CREATE_ACCOUNT_TEXT]: 'Crear cuenta',
  [Translations.EMAIL_LABEL]: 'Email *',
  [Translations.EMAIL_PLACEHOLDER]: 'Ingrese su direccion de email',
  [Translations.FORGOT_PASSWORD_TEXT]: '¿Olvido su contraseña?',
  [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]: 'Menos de dos tipos de MFA disponibles',
  [Translations.NEW_PASSWORD_LABEL]: 'Nueva contraseña',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Ingrese su nueva contraseña',
  [Translations.NO_ACCOUNT_TEXT]: '¿No tiene cuenta?',
  [Translations.USERNAME_REMOVE_WHITESPACE]: 'El nombre de usuario no puede contener espacios en blanco',
  [Translations.PASSWORD_REMOVE_WHITESPACE]: 'La contraseña no puede empezar con espacios en blanco',
  [Translations.PASSWORD_LABEL]: 'Contraseña *',
  [Translations.PASSWORD_PLACEHOLDER]: 'Ingrese su contraseña',
  [Translations.PHONE_LABEL]: 'Número telefónico *',
  [Translations.PHONE_PLACEHOLDER]: '(555) 555-1212',
  [Translations.QR_CODE_ALT]: 'qrcode',
  [Translations.RESET_PASSWORD_TEXT]: 'Cambiar contraseña',
  [Translations.RESET_YOUR_PASSWORD]: 'Reinicie su contraseña',
  [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: 'Seleccionar tipo de MFA',
  [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: 'Verify',
  [Translations.SEND_CODE]: 'Enviar código',
  [Translations.SUBMIT]: 'Enviar',
  [Translations.SETUP_TOTP_REQUIRED]: 'TOTP necesita ser configurado',
  [Translations.SIGN_IN_ACTION]: 'Ingresar',
  [Translations.SIGN_IN_HEADER_TEXT]: 'Inicie sesión en su cuenta',
  [Translations.SIGN_IN_TEXT]: 'Iniciar sesión',
  [Translations.SIGN_IN_WITH_AMAZON]: 'Inicio de sesión con Amazon',
  [Translations.SIGN_IN_WITH_AUTH0]: 'Inicio de sesión con Auth0',
  [Translations.SIGN_IN_WITH_AWS]: 'Inicio de sesión con AWS',
  [Translations.SIGN_IN_WITH_FACEBOOK]: 'Inicio de sesión con Facebook',
  [Translations.SIGN_IN_WITH_GOOGLE]: 'Inicio de sesión con Google',
  [Translations.SIGN_OUT]: 'Salir',
  [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: 'Email',
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: '¿Tiene una cuenta?',
  [Translations.SIGN_UP_HEADER_TEXT]: 'Crear una nueva cuenta',
  [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'Contraseña',
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Crear Cuenta',
  [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'Nombre de usuario',
  [Translations.SKIP]: 'Saltar',
  [Translations.SUCCESS_MFA_TYPE]: 'Success! Your MFA Type is now:',
  [Translations.TOTP_HEADER_TEXT]: 'Scan then enter verification code',
  [Translations.TOTP_LABEL]: 'Enter Security Code:',
  [Translations.TOTP_ISSUER]: 'AWSCognito',
  [Translations.TOTP_SETUP_FAILURE]: 'TOTP Setup has failed',
  [Translations.TOTP_SUBMIT_BUTTON_TEXT]: 'Verify Security Token',
  [Translations.TOTP_SUCCESS_MESSAGE]: 'Setup TOTP successfully!',
  [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]: 'Failed! Unable to configure MFA at this time',
  [Translations.USERNAME_LABEL]: 'Nombre de usuario o email *',
  [Translations.USERNAME_PLACEHOLDER]: 'Ingrese su usuario o email',
  [Translations.VERIFY_CONTACT_EMAIL_LABEL]: 'Email',
  [Translations.VERIFY_CONTACT_HEADER_TEXT]: 'Account recovery requires verified contact information',
  [Translations.VERIFY_CONTACT_PHONE_LABEL]: 'Phone Number',
  [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: 'Submit',
  [Translations.VERIFY_CONTACT_VERIFY_LABEL]: 'Verify',
  [Translations.ADDRESS_LABEL]: 'Address',
  [Translations.ADDRESS_PLACEHOLDER]: 'Enter your address',
  [Translations.NICKNAME_LABEL]: 'Nickname',
  [Translations.NICKNAME_PLACEHOLDER]: 'Enter your nickname',
  [Translations.BIRTHDATE_LABEL]: 'Birthday',
  [Translations.BIRTHDATE_PLACEHOLDER]: 'Enter your birthday',
  [Translations.PICTURE_LABEL]: 'Picture URL',
  [Translations.PICTURE_PLACEHOLDER]: 'Enter your picture URL',
  [Translations.FAMILY_NAME_LABEL]: 'Family Name',
  [Translations.FAMILY_NAME_PLACEHOLDER]: 'Enter your family name',
  [Translations.PREFERRED_USERNAME_LABEL]: 'Preferred Username',
  [Translations.PREFERRED_USERNAME_PLACEHOLDER]: 'Enter your preferred username',
  [Translations.GENDER_LABEL]: 'Gender',
  [Translations.GENDER_PLACEHOLDER]: 'Enter your gender',
  [Translations.PROFILE_LABEL]: 'Profile URL',
  [Translations.PROFILE_PLACEHOLDER]: 'Enter your profile URL',
  [Translations.GIVEN_NAME_LABEL]: 'First Name',
  [Translations.GIVEN_NAME_PLACEHOLDER]: 'Enter your first name',
  [Translations.ZONEINFO_LABEL]: 'Time zone',
  [Translations.ZONEINFO_PLACEHOLDER]: 'Enter your time zone',
  [Translations.LOCALE_LABEL]: 'Locale',
  [Translations.LOCALE_PLACEHOLDER]: 'Enter your locale',
  [Translations.UPDATED_AT_LABEL]: 'Updated At',
  [Translations.UPDATED_AT_PLACEHOLDER]: 'Enter the time the information was last updated',
  [Translations.MIDDLE_NAME_LABEL]: 'Middle Name',
  [Translations.MIDDLE_NAME_PLACEHOLDER]: 'Enter your middle name',
  [Translations.WEBSITE_LABEL]: 'Website',
  [Translations.WEBSITE_PLACEHOLDER]: 'Enter your website',
  [Translations.NAME_LABEL]: 'Full Name',
  [Translations.NAME_PLACEHOLDER]: 'Enter your full name',
  [Translations.PHOTO_PICKER_TITLE]: 'Picker Title',
  [Translations.PHOTO_PICKER_HINT]: 'Ancillary text or content may occupy this space here',
  [Translations.PHOTO_PICKER_PLACEHOLDER_HINT]: 'Placeholder hint',
  [Translations.PHOTO_PICKER_BUTTON_TEXT]: 'Button',
  [Translations.IMAGE_PICKER_TITLE]: 'Add Profile Photo',
  [Translations.IMAGE_PICKER_HINT]: 'Preview the image before upload',
  [Translations.IMAGE_PICKER_PLACEHOLDER_HINT]: 'Tap to image select',
  [Translations.IMAGE_PICKER_BUTTON_TEXT]: 'Upload',
  [Translations.PICKER_TEXT]: 'Pick a file',
  [Translations.TEXT_FALLBACK_CONTENT]: 'Fallback Content',
  [Translations.CONFIRM_SIGN_UP_FAILED]: 'Confirm Sign Up Failed',
  [Translations.SIGN_UP_FAILED]: 'Sign Up Failed'
})

I18n.setLanguage('es')
