export default {
  path: '/gestion',
  redirect: { name: 'Tenants' },
  component: () => import('@/layouts/default/DefaultLayout'),
  children: [
    {
      name: 'Gestión',
      path: 'index',
      component: () => import('./views/GestionView'),
      meta: {
        requiresAuth: true,
        title: 'Gestión',
        footer: true
      }
    }
  ]
}
