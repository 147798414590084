export default {
  path: '/securities',
  redirect: { name: 'Pagares' },
  component: () => import('@/layouts/default/DefaultLayout'),
  children: [
    {
      name: 'Pagares',
      path: 'index',
      component: () => import('./views/SecuritiesIndex'),
      meta: {
        requiresAuth: true,
        title: 'Pagarés',
        footer: true
      }
    },
    {
      name: 'PagareForm',
      path: 'create/:id',
      component: () => import('./views/SecurityForm'),
      meta: {
        requiresAuth: true,
        title: 'Create Pagaré',
        footer: true
      }
    },
    {
      name: 'PagareAdmin',
      path: 'admin/:id',
      component: () => import('./views/SecurityAdmin'),
      meta: {
        requiresAuth: true,
        title: 'Gestionar Pagaré',
        footer: true
      }
    }
  ]
}
