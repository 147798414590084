export default {
  path: '/temporary-security-access',
  redirect: { name: 'TemporaryAccess' },
  component: () => import('@/layouts/blank/Blank'),
  children: [
    {
      name: 'TemporaryAccess',
      path: '/temporary-security-access/:security_id',
      component: () => import('./views/TemporaryAccess'),
      meta: {
        requiresAuth: false,
        title: 'Acceso Temporal',
        footer: false
      }
    }
  ]
}
