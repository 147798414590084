// state
const state = {
  navigationDrawer: true,
  customizerDrawer: false,
  darkMode: false,
  primaryColor: '#1C4A75',
  userData: null,
  snackbar: null
}

const getters = {
  snackbar (state) {
    return state.snackbar
  },
  userData (state) {
    return state.userData
  },
  permissions: state => {
    return state.userData?.permissions
  },
  userTenants: state => {
    return state.userData?.tenants
  }
}

// annotations
const actions = {
}

// mutations
const mutations = {
  SET_CUSTOMIZER_DRAWER (state, show) {
    state.customizerDrawer = show
  },
  SET_NAVIGATION_DRAWER (state, show) {
    state.navigationDrawer = show
  },
  SET_THEME_MODE (state, dark) {
    state.darkMode = dark
  },
  SET_THEME_COLOR (state, color) {
    state.primaryColor = color
  },
  SET_USER_DATA (STATE, userData) {
    state.userData = userData
  },
  SET_SNACKBAR (state, data) {
    const timeout = 3000
    const color = 'success'
    let message = ''
    message = data.message
    state.snackbar = {
      timeout: timeout,
      message: message,
      color: data.color || color,
      show: true
    }
  },

  SET_SNACKBAR_ERROR (state, error) {
    let message = 'El servidor no responde'
    let textErrors = ''
    let timeout = 7000

    if (typeof error === 'string') {
      message = `<strong>${error}</strong>`
    }

    if (error.response) {
      message = `<strong>${error.response?.data?.message}</strong>`
      const errors = Object.values(error.response?.data?.errors || {})
      textErrors = errors ? errors.join('<br/>') : ''
      timeout = (errors.length || 1) * 5000
    }
    state.snackbar = {
      timeout: timeout || 5000,
      message: [message, textErrors].join('<br/>') || '',
      color: error.color || 'error'
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
