<template>
  <v-app id="inspire">
    <router-view/>
  </v-app>
</template>

<script>
import { getFreshDesk } from '@/layouts/default/helpDeskFunctions'

export default {
  name: 'App',
  beforeUpdate () {
    getFreshDesk()
  },
  created () {
    const freshworks = document.createElement('script')
    freshworks.setAttribute('src', process.env.VUE_APP_WIDGET_HELP_DESK_URL)
    freshworks.setAttribute('async', 'async')
    freshworks.setAttribute('defer', 'defer')
    document.head.appendChild(freshworks)
  }
}
</script>
