const getFreshDesk = (userData = null) => {
  window.fwSettings = { widget_id: process.env.VUE_APP_WIDGET_ID }

  !(function () {
    if (typeof window.FreshworksWidget !== 'function') {
      const n = function () {
        n.q.push(arguments)
      }
      n.q = [],
      window.FreshworksWidget = n
    }
  }())

  const fields = {
    name: userData?.name,
    email: userData?.email,
    custom_fields: {
      cf_tenant: userData?.tenant
    }
  }

  FreshworksWidget('identify', 'ticketForm', fields)
  FreshworksWidget('disable', 'ticketForm', ['product_id'])
}

export { getFreshDesk }
